import React, {useState, useContext, useEffect} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Toast from "../../utils/sharedComponents/Toast";
import Menu from "../Menu/Menu";
import {AppContext} from "../../context/AppContext";
import Location from '@material-ui/icons/LocationOn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationCity from '@material-ui/icons/LocationCity';
import Speed from '@material-ui/icons/Speed';
import CarCrash from '@material-ui/icons/DirectionsCar';
import Traffic from '@material-ui/icons/Traffic';
import LocalHospital from '@material-ui/icons/LocalHospital';
import CarRepair from '@material-ui/icons/DirectionsCar';
import Build from '@material-ui/icons/Build';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Alarm from '@material-ui/icons/Alarm';

import AppBarSpacer from "../../utils/sharedComponents/AppBarSpacer";
import {useHistory} from "react-router";
import MenuListItems from "../CommonComponents/menuListItems";
import MapViewScratch from "../Map/MapViewScratch";
import {
  getAddressStats,
  getChartData,
  getObjectFromArrayThatMatchesValue,
  getStreetScore
} from "../../utils/sharedFunctions";
import useGaTracker from "../../utils/hooks/useGaTracker";
import StatItem from "./statItem";
import './dashboard.css'
import shield from '../../assets/badge.png'
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

const drawerWidth = 240;

const Dashboard = () => {
  const user = true
  const history = useHistory()
  useGaTracker()

  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  // useProtectRoute()
  // const searchObject = {searchTerm: '2524 WEST STREET SE WASHINGTON DC 20020', 'blockkey': '0034a11c0ce420bdc946f562800e60c0'}
  const classes = useStyles();
  const {state: {searchObject, test, allStreetStats}, dispatch} = useContext(AppContext);
  const [toastStatus, setToastStatus] = useState(null);
  const [barChartData, setBarChartData] = useState();
  const [lineChartData, setLineChartData] = useState(null);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const theme = useTheme()


  function showToast(toastType, toastMessage) {
    setToastStatus({
      toastType: toastType,
      toastMessage: toastMessage,
      date: Date()
    })
  }

  useEffect(() => {
    async function getData() {
      try {
        const allStreetStats = await getAddressStats(searchObject.searchTerm)
        dispatch({allStreetStats: allStreetStats[0]})
        const streetScore = await getStreetScore(allStreetStats[0].blockgroup_key)
        allStreetStats[0].streetScore = parseInt(streetScore[0].final_score_scaled)
        dispatch({allStreetStats: allStreetStats[0]})
        const chartData = await getChartData(allStreetStats[0].blockgroup_key, 'bar')
        const lineChartData = await getChartData(allStreetStats[0].blockgroup_key, 'line')
        setBarChartData(chartData)
        setLineChartData(lineChartData)
      } catch (e) {
        console.log(e)
        showToast('error', 'Not enough data for credible results, please try a different address')
        setTimeout(() => history.push('/'), 4500)
      }
    }

    getData()
  }, [user]);

  //TODO: ENABLE VERIFICATION PROTECTION
  // if (user && !user.emailVerified) {
  //     console.log(!user.emailVerified)
  //     return (<VerifyEmail/>)
  // }

  // if (!searchObject) {
  //     return null
  // }
  function getNameForRating(integer) {
    switch (parseInt(integer)) {
      case 1:
        return "Low"
      case 2:
        return "Low"
      case 3:
        return "Medium"
      case 4:
        return "High"
      case 5:
        return "High"
    }
  }

  function getColorForRating(rating) {
    switch (parseInt(rating)) {
      case 1:
        return "green"
      case 2:
        return 'green'
      case 3:
        return 'orange'
      case 4:
        return 'darkred'
      case 5:
        return 'darkred'
    }
  }

  if (!allStreetStats.streetScore) {
    return null
  }

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <div>
        <Menu streetScore={allStreetStats.streetScore} listOfItemsToRender={<MenuListItems/>} doNotRenderOptions={true}
              addressToRender={searchObject.searchTerm}/>
        <Fade in={true} timeout={2000}>
          {allStreetStats &&
          <main className={classes.content}>
            {/*<AppBarSpacer/>*/}
            <Grid container direction={"row"} style={{marginTop: 50}}>
              <Grid item xs={12} md={5} lg={5} xl={5}
                    style={{
                      elevation: 'above',
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 20,
                      zIndex: 1,
                      backgroundColor: '#ffffff',
                      boxShadow: "0px 2px 38px rgba(0, 0, 0, 0.2)"
                    }}>

                <section style={{marginTop: 20, textAlign: 'start'}}>
                  <Grid container direction={"column"} style={{width: '100%', height: '100%', overflow: 'auto'}}
                        spacing={2}>
                    <Grid item style={{marginBottom: 10}}>
                      <Typography variant={'h5'}>
                        {/*<strong>Compared to other streets in the city</strong>*/}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <StatItem
                        rating={getNameForRating(allStreetStats.overall_traffic_level)}
                        color={getColorForRating(allStreetStats.overall_traffic_level)}
                        text={`level of traffic across Washington DC`}
                        icon={<LocalShipping/>}
                      />
                    </Grid>
                    <Grid item>
                      <StatItem
                        text={`level of traffic at rush hour`}
                        icon={<Alarm/>}
                        rating={getNameForRating(allStreetStats.rush_hour_level)}
                        color={getColorForRating(allStreetStats.rush_hour_level)}
                      />
                    </Grid>
                    <Grid item>
                      <StatItem
                        text={`level of traffic compared to similar streets in ${allStreetStats.zipcode}`}
                        color={getColorForRating(allStreetStats.traffic_vs_nearby_streets)}
                        rating={getNameForRating(allStreetStats.traffic_vs_nearby_streets)}
                        icon={<LocationCity/>}
                      />
                    </Grid>
                    <Grid item>
                      <StatItem
                        text={`rate of speeding`}
                        rating={getNameForRating(allStreetStats.speeding_rate)}
                        color={getColorForRating(allStreetStats.speeding_rate)}
                        icon={<Speed/>}
                      />
                    </Grid>
                    <Grid item>
                      <StatItem
                        text={`risk of crashes`}
                        rating={getNameForRating(allStreetStats.elevated_crashes_risk)}
                        color={getColorForRating(allStreetStats.elevated_crashes_risk)}
                        icon={<CarCrash/>}
                      />
                    </Grid>
                    <Grid item>
                      <StatItem
                        text={`Based on ${allStreetStats.total_ems_incidents} calls, the average EMS response time
                        to this block is ${Math.round(allStreetStats.block_avg_ems_response_time)} minutes, versus the 
                        citywide average of 9.4 minutes`}
                        icon={<LocalHospital/>}
                      />
                    </Grid>


                    <Grid item xs={12} style={{padding: 5, marginTop: 20, marginBottom: 20}}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant={'h5'}>
                            How does weekday traffic on this street compare to other similar
                            streets
                            in <strong>{allStreetStats.zipcode}</strong>?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {barChartData &&
                          <BarChart data={barChartData} zipcode={allStreetStats.zipcode}/>}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography variant={'h5'}>
                            How does speeding on this street compare to other similar
                            streets
                            in <strong>{allStreetStats.zipcode}</strong>?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {lineChartData &&
                          <LineChart data={lineChartData} zipcode={allStreetStats.zipcode}/>}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </section>
              </Grid>

              <Grid item xs={12} md={7} lg={7} xl={7}
                    style={{width: '100%', height: '100%'}}>
                <MapViewScratch coords={[
                  [allStreetStats.end_lon,
                    allStreetStats.end_lat],
                  [allStreetStats.start_lon,
                    allStreetStats.start_lat]
                ]}/>
              </Grid>
            </Grid>
          </main>}
        </Fade>
      </div>

      {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}
    </div>
  );
}

export default Dashboard

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: 20
  },
  root: {
    // display: 'flex',
    backgroundColor: theme.palette.neutral.gray,
    overflow: "hidden",
    overFlowY: 'hidden'

  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Comfortaa',

  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '98vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    // padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  modalCustom: {
    backgroundColor: 'white',
    margin: 40,
    padding: 40
  },
  fixedMinHeight: {
    minHeight: 240,
    marginTop: 10
  },
  modal: {
    borderRadius: 10
  },
  addSruveyBtn: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  columnTitle: {
    fontFamily: 'Comfortaa',
    margin: 10
  },
  fab: {
    position: "fixed",
    top: 80,
    right: 10,
  },
  fabTutorial: {
    position: "fixed",
    borderRadius: 30,
    bottom: 80,
    right: 10,
  },
  addClientText: {
    // marginTop: 20,
    // textAlign: 'center',
    // padding: 20
  },
  typograhy1: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 5,
    // borderStyle: 'solid',
    // borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10
    // color: theme.palette.primary.light,
  }
}));
