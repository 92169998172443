import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const HomePageItemCard = ({imgSrc, imageLeft, header, text}) => {
    const classes = useStyles();
    const theme = useTheme()

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowDimensions;
    }

    const {height, width} = useWindowDimensions();

    function directionToDisplay() {
        console.log(width)
        if (width > 800) {
            console.log('row')
            return 'row'
        } else {
            console.log('column')
            return 'column'
        }
    }

    const isMobile = width < 600


    if (imageLeft || isMobile) {
        return (
            <Grid container direction={"row"} alignContent={"center"} alignItems={"center"}
                  style={{textAlign: "left", marginTop: '10vh'}}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.img}>
                    <img alt={'img'} src={imgSrc} style={{maxHeight: 300, maxWidth: '70%'}}/>

                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.text}>
                    <Typography variant={"h3"} style={{marginBottom: 10}}><strong>{header}</strong></Typography>
                    <Typography variant={"body1"}>{text}</Typography>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container direction={"row"} alignContent={"center"} alignItems={"center"}
                  style={{textAlign: "right", marginTop: '10vh'}}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.text}>
                    <Typography variant={"h3"} style={{marginBottom: 10}}><strong>{header}</strong></Typography>
                    <Typography variant={"body1"}>{text}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.img}>
                    <img alt={'img'} src={imgSrc} style={{maxHeight:300, maxWidth: '70%'}}/>
                </Grid>
            </Grid>
        );

    }
};

export default HomePageItemCard;

const useStyles = makeStyles((theme) => ({
    img: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        alignContent: 'center',

    },
    text: {
        [theme.breakpoints.down('sm')]: {
            // width: theme.spacing(9),
            textAlign: 'center',
        },
        padding: 20
    }
}));

