import React, {createContext, useReducer} from 'react'

export const AppContext = createContext();

const initialState = {
    allStreetStats: {
        avg_speed: "10.5591506717850288",
        avg_speed_nearby_similar_streets: "14.4994642768199574",
        avg_speed_zip_major_arterials: "18.9410811419682091",
        avg_traffic_volume: "12.4790419161676647",
        avg_traffic_volume_nearby_similar_streets: "20.6553497942386831",
        avg_traffic_volume_non_rush_hour: "12.1678832116788321",
        avg_traffic_volume_rush_hour: "13.9000000000000000",
        avg_traffic_volume_zip_major_arterials: "41.8346230158730159",
        block_avg_ems_response_time: 6.8954829166666665,
        block_name: "2700 - 2799 BLOCK OF P STREET NW",
        blockgroup_geo: "0102000020E610000013000000EE093521814353C0B84363C066744340717C89B5824353C0C08F58BE667443408EBDBECF864353C05AC7E6AB6674434027BF99898D4353C076C6C1796674434083EC635B8F4353C0CD442A6C66744340DA05B51A914353C041CF315F66744340E1BF637F914353C019183A5C667443408B20B0C1964353C02139932A66744340D8C597C09C4353C0A8559A0866744340DCC3EE68A24353C0865C6AE1657443401631A0ADA54353C02CD1C6B865744340B3DD8FC7A74353C0F046A29E6574434046615738A94353C06ACCBD8C65744340FC47068DB04353C0346593316574434084DD7C0DB24353C081DD5A4665...",
        blockgroup_key: "f9842f47c74939a2c0bfdfa14edc7027",
        citywide_avg_response_time: 9.387312828992682,
        elevated_crashes_risk: "does not appear to have",
        end_lat: 38.90933783780985,
        end_lon: -77.05818818666086,
        final_score: "51",
        final_score_scaled: "63",
        num_crashes: "0",
        num_serious_crashes: "0",
        overall_traffic_level: "Medium",
        pct_traffic_over_25: "0.02548166563082660037",
        pct_traffic_over_25_nearby_similar_streets: "0.18496237851872845793",
        raw_score: "0.0063146229665620809453519581759739256791744881229810990415697079379316698704000000000000000",
        routename: "P ST NW",
        rush_hour_increase_factor: "1.1423515296940612",
        rush_hour_level: "not much busier",
        score_max: "99",
        score_min: "-31",
        similar_streets_group: "21Yes20007",
        speeding_rate: "low",
        start_lat: 38.90938572737599,
        start_lon: -77.05475645236558,
        streetScore: 63,
        total_ems_incidents: "4",
        traffic_volume_vs_max: "0.29829459468136786737",
        traffic_volume_vs_peer_streets: "0.60415543868680430864",
        traffic_vs_nearby_streets: "about the same amount of traffic as",
        website_address: "2700 P STREET NW WASHINGTON DC 20007",
        zipcode: 20007,
    },
    searchObject: {
        searchTerm: "2700 P STREET NW WASHINGTON DC 20007",
        blockkey: null
    }
}

const reducer = (state, action) => {
    return {...state, ...action}
};

export const AppContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            {props.children}
        </AppContext.Provider>
    )
};
