import React, {useContext, useEffect, useState} from 'react';
import traffic from "../../assets/traffic.png";
import accidents from "../../assets/paramedic.png";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import './homepage.css'
import {useHistory} from "react-router";
import {AppContext} from "../../context/AppContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useGaTracker from "../../utils/hooks/useGaTracker";
import {
  getAllPlaces,
  getStreetsForZipCode,
  getZipCodes,
  sortArrayByNumericObjectProperty
} from "../../utils/sharedFunctions";
import fuzzysort from "fuzzysort";
import mother from "../../assets/Vector 3.png";
import {Box, Container, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import HomePageItemCard from "./HomePageItemCard";
import Fade from "@material-ui/core/Fade";


const Test = () => {

  const classes = useStyles();
  const [toastStatus, setToastStatus] = useState(null);
  const [addresses, setAddresses] = useState([])
  const [addressOptions, setAddressOptions] = useState([])
  const [city, setCity] = useState('Washington DC')
  const [zipCodes, setZipcodes] = useState([])
  const [zipCode, setZipCode] = useState(null)
  const history = useHistory()
  const {state: {continueToStep2, userDataObject}, dispatch} = useContext(AppContext);
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  useGaTracker()
  const fuzzyOptions = {
    threshold: -1000, // Don't return matches worse than this (higher is faster)
    limit: 5, // Don't return more results than this (lower is faster)
    allowTypo: true, // Allwos a snigle transpoes (false is faster)
    key: 'website_address', // For when targets are objects (see its example usage)
    keys: null, // For when targets are objects (see its example usage)
    scoreFn: null, // For use with `keys` (see its example usage)
  }

  async function getAddress() {
    try {
      const addresses = await getAllPlaces()
      return addresses
    } catch (e) {
    }
  }

  async function getAllZipcodesForCity() {
    try {
      const zipCodes = await getZipCodes(city)
      return zipCodes
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (zipCode) {
      getStreetsForZipCode(zipCode).then(addressesServer => {
        addressesServer.forEach(t => t.filePrepared = fuzzysort.prepare(t.website_address))
        setAddresses(addressesServer)
        keyboardEvent()
      })
    }
  }, [zipCode])

  useEffect(() => {
    getAllZipcodesForCity(city).then(zipCodes => {
      zipCodes = sortArrayByNumericObjectProperty(zipCodes, 'zipcode')
      setZipcodes(zipCodes)
    })
  }, [city])


  function showToast(toastType, toastMessage) {
    setToastStatus({
      toastType: toastType,
      toastMessage: toastMessage,
      date: Date()
    })
  }

  const items = [
    {
      header: 'Average Speed',
      text: "Is this a street where you can let go of your child’s hand? Check out what is the average speed on your street. " +
        "Slower driving is safer driving.",
      imageLeft: true,
      imgSrc: mother,
    },
    {
      header: 'Traffic Volume',
      text: "A peaceful street you'll enjoy, or will you have many sleepless mornings filled with honks and revs? Is this a true neighborhood street, or a cut-through? Will you be able to back out of your driveway during rush hour?",
      imageLeft: false,
      imgSrc: traffic,
    },
    {
      header: 'Accidents',
      text: "How many accidents happened on your street? What is the chance you or someone dear will have the same experience on that street? : Is this block at elevated risk for crashes? How long will it take EMS to respond?",
      imageLeft: true,
      imgSrc: accidents,
    },
  ]

  function getSearchResults(searchTerm) {
    addressOptions.length = 0
    const results = fuzzysort.go(searchTerm, addresses, fuzzyOptions)
    setAddressOptions(results)
  }

  function showDetailedView(e) {
    const searchTerm = e.currentTarget.getAttribute('street-name')
    const block = e.currentTarget.getAttribute('block')
    // console.log(block, 'bock')
    dispatch({searchObject: {searchTerm: searchTerm, blockkey: block}})
    history.push('/dashboard')
  }

  // Defining the ref constant variable
  const inputRef = React.useRef(null);

  // example use
  const keyboardEvent = () => {
    inputRef.current.focus(); //Trigger click
  }
  return (
    <div>
      <Fade in={true} timeout={2000}>
        {/*<MenuHomePage/>*/}
        <div>

          <div id={'container'}>
            <div className={'overlay'}></div>
            <video autoPlay muted loop id="myVideo"
                   poster={'https://images.unsplash.com/photo-1598966835412-6de6f92c243d?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687'}>
              <source src={'./video-traffic.mp4'} type="video/mp4"/>
            </video>
          </div>

          <Grid container direction={"column"} alignItems={'center'} alignContent={"center"}
                className={classes.getStartedContainer}
          >
            <Grid className={classes.gridItem} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.text} variant={"h1"}><strong>Your Street's Traffic
                Safety</strong></Typography>
              {/*<Typography className={classes.text} variant={"h1"}><strong>Traffic SafetyScore</strong></Typography>*/}
              <Typography className={classes.text} variant={"h4"}>type in an address and check out the
                streetscore</Typography>
            </Grid>

            <Grid item container direction={"row"} xs={12} sm={6} md={6} lg={6} xl={6}
                  alignItems={"center"} alignContent={"center"} justifyContent={"center"}
                  style={{
                    width: '100%', maxWidth: 600, backgroundColor: 'white',
                    borderRadius: '20px 20px 0px 0px', marginTop: 70, paddingTop: 20, paddingBottom: 10
                  }}>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"Washington DC"}
                    label="City"
                    onChange={() => {
                      // console.log('test')
                    }}
                  >
                    <MenuItem value={"Washington DC"}>Washington DC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {zipCodes &&
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Zip Code</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={zipCode}
                    label="Zip Code"
                    autoWidth
                    inputProps={{style: {width: 150}}}
                    style={{width: 150}}
                    onChange={(e) => {
                      setZipCode(e.target.value)
                    }}
                  >
                    {zipCodes.map(zipCode => {
                      return (
                        <MenuItem
                          value={zipCode.zipcode.toString()}>{zipCode.zipcode}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>}
            </Grid>


            <Grid item style={{
              width: '100%',
              maxWidth: 600,
              backgroundColor: 'white',
              borderRadius: '0px 0px 20px 20px',
              paddingTop: 5,
              paddingBottom: 10
            }}>

              <TextField id="outlined-basic"
                         InputProps={{
                           startAdornment: (
                             <InputAdornment>
                               <IconButton>
                                 <SearchIcon/>
                               </IconButton>
                             </InputAdornment>
                           ),
                           classes: {
                             root: classes.input,
                           },
                         }}
                         label=""
                         variant="outlined"
                         placeholder={zipCode === null ? "select city & zip code" : '25 Wood Ln, Washington'}
                         autoFocus={zipCode !== null}
                         disabled={zipCode === null}
                         ref={inputRef}
                         // fullWidth
                         style={{
                           backgroundColor: 'white',
                           borderRadius: 90,
                           padding: 10,
                           marginBottom: 0,
                           maxWidth: 600,
                           minWidth: '80%'
                         }}
                         size={'medium'}
                         onChange={(e) => getSearchResults(e.target.value)}

              />

              {addressOptions.map(option => {
                return <Typography key={option.target} street-name={option.target} block={option.obj.blockkey}
                          style={{
                            margin: 0,
                            textAlign: 'center',
                            padding: 10,
                            // paddingLeft: 56,
                            cursor: 'pointer'
                          }}
                          className={classes.ptag}
                          onClick={(e) => showDetailedView(e)}>
                  {option.target}
                </Typography>
              })}
              {/*</div>*/}
            </Grid>

          </Grid>
          <section style={{marginTop: '5%', backgroundColor: 'white',}}>
            <Container maxWidth={'lg'}>
              {items.map(item => {
                return <HomePageItemCard
                  header={item.header}
                  text={item.text}
                  imageLeft={item.imageLeft}
                  imgSrc={item.imgSrc}/>
              })}
            </Container>
          </section>
        </div>

      </Fade>
    </div>
  );
};

export default Test;

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: 40,
    maxWidth: 600,
    // width: 500
  },
  ptag: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
  },

  root: {
    backgroundColor: '#ffffff',
  },
  footer: {
    background: '#292D2C 0% 0% no-repeat padding-box',
    opacity: 1,
    color: 'white',
    textAlign: 'center'
  },
  img: {
    width: '40%'
  },
  gridItem: {
    // margin: theme.spacing(2)
  },
  getStartedContainer: {
    padding: 20,
    minWidth: '100%',
    maxWidth: 1200,
    textAlign: 'center',
    position: 'relative',
    zIndex: 10
  },
  text: {
    // color: theme.palette.neutral.main,
    color: 'whitesmoke',
    // textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
    margin: theme.spacing(3)
  },
  getStartedButton: {
    width: '60%',
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    margin: 10,
    borderRadius: 30
  },

  getStartedBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.neutral.main,
    maxWidth: '80%',
    minHeight: '40vh',
    borderRadius: 10,
    padding: theme.spacing(3),
    marginBottom: 10,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',

  },
  titleText: {
    color: theme.palette.primary.light,
    margin: theme.spacing(4),
    marginBottom: 50,
    textAlign: 'center'
  },
  howItWorksContainer: {
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.neutral.gray
  },


}))

