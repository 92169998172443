import React from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import {Slider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const StatItem = ({
                    icon, text, sliderValue,
                    traffic_volume,
                    rating,
                    color,
                    sliderMinValue,
                    sliderMaxValue,
                    subText
                  }) => {
  const classes = useStyles();

  return (
    <Grid container direction={"column"}>

      <Grid item style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 0,
      }}>
        {icon}
        <Typography variant={"body1"} style={{color: color, marginLeft: 10}}><strong>{rating}</strong></Typography>
        <Typography variant={"body1"} style={{marginLeft: 5}}>{text}</Typography>
      </Grid>
      {subText && <Grid item style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: -5,
      }}>
        <div style={{visibility: "hidden"}}>
          {icon}
        </div>
        <Typography variant={"body1"}
                    style={{marginLeft: 5, color: 'gray', fontSize: '0.7rem'}}>{subText}</Typography>
      </Grid>
      }

      {sliderValue &&
      <Grid item xs={12}
            style={{
              display: "flex", marginTop: 5, marginLeft: 5,
              alignItems: "flex-start", alignContent: "flex-start", justifyContent: 'flex-start'
            }}>
        <Slider
          style={{width: 200}}
          value={sliderValue}
          min={sliderMinValue}
          max={sliderMaxValue}
        />
      </Grid>}
    </Grid>
  );
};

export default StatItem;

const useStyles = makeStyles((theme) => ({
  typograhy1: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 5,
    // borderStyle: 'solid',
    // borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10
    // color: theme.palette.primary.light,
  }
}));

StatItem.propTypes = {
  icon: PropTypes.any,
  sliderMaxValue: PropTypes.any,
  sliderMinValue: PropTypes.any,
  sliderValue: PropTypes.any,
  text: PropTypes.any,
  traffic_volume: PropTypes.any
}
