import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toast from "../../utils/sharedComponents/Toast";
import MenuHomePage from "./MenuHomePage";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import fuzzysort from "fuzzysort";
import {getAllPlaces, getStreetsForZipCode, getZipCodes} from "../../utils/sharedFunctions";
import TextField from "@material-ui/core/TextField";
import {useHistory} from "react-router";
import {AppContext} from "../../context/AppContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import HomePageItemCard from "./HomePageItemCard";
import tow from '../../assets/tow.svg'
import map from '../../assets/map.svg'
import speed from '../../assets/speed.svg'
import fixRoad from '../../assets/fix-road.svg'
import traffic from '../../assets/traffic.svg'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useGaTracker from "../../utils/hooks/useGaTracker";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import video from '../../assets/background.webp'
import './homepage.css'

const HomePage = () => {

  const [toastStatus, setToastStatus] = useState(null);
  const classes = useStyles();
  const [addresses, setAddresses] = useState([])
  const [addressOptions, setAddressOptions] = useState([])
  const [city, setCity] = useState('Washington DC')
  const [zipCodes, setZipcodes] = useState([])
  const [zipCode, setZipCode] = useState(null)
  const history = useHistory()
  const {state: {continueToStep2, userDataObject}, dispatch} = useContext(AppContext);
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  useGaTracker()
  const fuzzyOptions = {
    threshold: -1000, // Don't return matches worse than this (higher is faster)
    limit: 5, // Don't return more results than this (lower is faster)
    allowTypo: true, // Allwos a snigle transpoes (false is faster)
    key: 'website_address', // For when targets are objects (see its example usage)
    keys: null, // For when targets are objects (see its example usage)
    scoreFn: null, // For use with `keys` (see its example usage)
  }

  async function getAddress() {
    try {
      const addresses = await getAllPlaces()
      console.log(addresses)
      return addresses
    } catch (e) {
      console.log(e)
    }
  }

  async function getAllZipcodesForCity() {
    try {
      const zipCodes = await getZipCodes(city)
      console.log(zipCodes)
      return zipCodes
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    console.log('effect')
    if (zipCode) {
      getStreetsForZipCode(zipCode).then(addressesServer => {
        console.log(addressesServer)
        addressesServer.forEach(t => t.filePrepared = fuzzysort.prepare(t.website_address))
        setAddresses(addressesServer)
        keyboardEvent()
      })
    }
  }, [zipCode])

  useEffect(() => {
    console.log('effect')
    getAllZipcodesForCity(city).then(zipCodes => {
      console.log(zipCodes)
      setZipcodes(zipCodes)
    })
  }, [city])


  function showToast(toastType, toastMessage) {
    setToastStatus({
      toastType: toastType,
      toastMessage: toastMessage,
      date: Date()
    })
  }

  const items = [
    {
      header: 'Average Speed',
      text: "Check out what is the average speed on your street. Slower driving is safer driving.",
      imageLeft: true,
      imgSrc: speed,
    },
    {
      header: 'Traffic Volume',
      text: "Is it a peaceful street you'll enjoy, or will the car noise keep you awake at night?",
      imageLeft: false,
      imgSrc: traffic,
    },
    {
      header: 'Crashes',
      text: "How many crashes have recently happened on your street? Will your family be at elevated risk?",
      imageLeft: true,
      imgSrc: tow,
    },
    // {
    //     text: 'Did you street need any safety improvements? How many safety improvements were asked for?',
    //     header: "Safety Improvements",
    //     imageLeft: false,
    //     imgSrc: fixRoad,
    // }
  ]

  function getSearchResults(searchTerm) {
    addressOptions.length = 0
    const results = fuzzysort.go(searchTerm, addresses, fuzzyOptions)
    setAddressOptions(results)
  }

  function showDetailedView(e) {
    const searchTerm = e.currentTarget.getAttribute('street-name')
    const block = e.currentTarget.getAttribute('block')
    console.log(block, 'block')
    dispatch({searchObject: {searchTerm: searchTerm, blockkey: block}})
    history.push('/dashboard')
  }

  function simulateClick(e) {
    e.click()
  }

  // Defining the ref constant variable
  const inputRef = React.useRef(null);

  // example use
  const keyboardEvent = () => {
    inputRef.current.focus(); //Trigger click
  }
  return (
    <div className={classes.root}>
      <video autoPlay muted loop id="myVideo" poster={speed}>
        <source src={'./video-traffic.mp4'} type="video/mp4"/>
      </video>

      <MenuHomePage/>
      <Grid container direction={"column"} alignItems={'center'} alignContent={"center"}
            className={classes.getStartedContainer}
      >
        <Grid className={classes.gridItem} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.text} variant={"h1"}><strong>Your Street's Safety, In
            Numbers</strong></Typography>
          <Typography className={classes.text} variant={"h4"}>type in an address and check out the
            score</Typography>
        </Grid>

        <Grid item container direction={"row"} xs={12} sm={6} md={6} lg={6} xl={6}
              spacing={3} alignItems={"center"} alignContent={"center"} justifyContent={"center"}
              style={{width: '100%', maxWidth: 600, backgroundColor: 'white', borderRadius: 20, marginTop: 70}}>
          <Grid item>
            <FormControl>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={"Washington DC"}
                label="City"
                onChange={() => {
                  console.log('test')
                }}
              >
                <MenuItem value={"Washington DC"}>Washington DC</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {zipCodes &&
          <Grid item>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Zip Code</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={zipCode}
                label="Zip Code"
                onChange={(e) => {
                  setZipCode(e.target.value)
                }}
              >
                {zipCodes.map(zipCode => {
                  return (
                    <MenuItem value={zipCode.zipcode.toString()}>{zipCode.zipcode}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>}

        </Grid>
        <Grid item style={{width: '100%', maxWidth: 600, minWidth:400, backgroundColor: 'white', borderRadius: 20}}>
          <TextField
            id="outlined-basic"
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon/>
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: classes.input,
              },
            }}
            label=""
            variant="outlined"
            placeholder={zipCode === null ? "select city & zip code first" : '25 Wood Ln, Washington'}
            autoFocus={zipCode !== null}
            disabled={zipCode === null}
            ref={inputRef}
            fullWidth
            style={{backgroundColor: 'white', borderRadius: 90, padding: 10, marginBottom: 0, minWidth: '100%'}}
            size={'large'}
            onChange={(e) => getSearchResults(e.target.value)}
          />
          {console.log('optionnnnnnnnnnnnnnnnnnnnnnn')}

          {addressOptions.map(option => {
            console.log('optionnnnnnnnnnnnnnnnnnnnnnn')
            console.log('option')
            console.log(option)
            return <Typography
              key={option.target}
              street-name={option.target}
              block={option.obj.blockkey}
              style={{
                margin: 0,
                textAlign: 'center',
                padding: 10,
                paddingLeft: 56,
                cursor: 'pointer'
              }}
              className={classes.ptag}
              onClick={(e) => showDetailedView(e)}>
              {option.target}
            </Typography>
          })}
        </Grid>
      </Grid>


      <section style={{marginTop: '5%'}}>
        {items.map(item => {
          return <HomePageItemCard
            header={item.header}
            text={item.text}
            imageLeft={item.imageLeft}
            imgSrc={item.imgSrc}/>
        })}
      </section>

      {toastStatus ? <Toast key={toastStatus.date} toastStatus={toastStatus}/> : null}

    </div>
  );
};

export default HomePage;

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: 40,
    minWidth: '80%'
  },
  ptag: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    },
  },

  root: {
    backgroundColor: '#ffffff',
  },
  footer: {
    background: '#292D2C 0% 0% no-repeat padding-box',
    opacity: 1,
    color: 'white',
    textAlign: 'center'
  },
  img: {
    width: '40%'
  },
  gridItem: {
    // margin: theme.spacing(2)
  },
  getStartedContainer: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(2),
    // minHeight: '100vh',
    minWidth: '100%',
    maxWidth: 1200,
    textAlign: 'center'
  },
  text: {
    // color: theme.palette.neutral.main,
    color: 'white',
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
    margin: theme.spacing(3)
  },
  getStartedButton: {
    width: '60%',
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    margin: 10,
    borderRadius: 30
  },

  getStartedBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.neutral.main,
    maxWidth: '80%',
    minHeight: '40vh',
    borderRadius: 10,
    padding: theme.spacing(3),
    marginBottom: 10,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',

  },
  titleText: {
    color: theme.palette.primary.light,
    margin: theme.spacing(4),
    marginBottom: 50,
    textAlign: 'center'
  },
  howItWorksContainer: {
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.neutral.gray
  },


}))

