import React, {useEffect, useState} from 'react';
import {
    DiscreteColorLegend,
    HorizontalGridLines,
    LabelSeries, VerticalBarSeries,
    VerticalBarSeriesCanvas,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis
} from "react-vis";
import Grid from "@material-ui/core/Grid";

const BarChart = ({data, zipcode}) => {
    // const {useCanvas} = this.state;
    // const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS';
    const [firstSeries, setFirstSeries] = useState([])
    const [secondSeriesBlueData, setSecondSeriesBlueData] = useState([])


    useEffect(() => {
        function extractName(dbName, similarStreet) {
            let nameArray = dbName.toLowerCase().split('_')
            console.log('nameArray')
            console.log(nameArray)

            if (similarStreet) {
                return nameArray[3] + "-" + nameArray[4]
            } else {
                return nameArray[2] + "-" + nameArray[3]
            }
        }


        let firstSeries = [];
        let secondSeries = [];
        console.log(data.similarStreetStats[0])
        let firstSeriesKeys = Object.keys(data.streetStats[0])
        let firstSeriesValues = Object.values(data.streetStats[0])

        let secondSeriesKeys = Object.keys(data.similarStreetStats[0])
        let secondSeriesValues = Object.values(data.similarStreetStats[0])

        console.log(firstSeriesKeys)
        console.log(firstSeriesValues)
        console.log('---------- second ')
        console.log(secondSeriesKeys)
        console.log(secondSeriesValues)

        for (let i = 0; i < firstSeriesKeys.length; i++) {
            if (firstSeriesKeys[i].includes("weekday")) {
                firstSeries.push({
                    x: extractName(firstSeriesKeys[i], false),
                    y: parseFloat(firstSeriesValues[i])
                })
                secondSeries.push({
                    x: extractName(secondSeriesKeys[i], true),
                    y: parseFloat(secondSeriesValues[i])
                })
            }
        }

        console.log('------------- series ---------')
        console.log(firstSeries)
        console.log(secondSeries)
        setFirstSeries(firstSeries)
        setSecondSeriesBlueData(secondSeries)
    }, [data])

    const BarSeries = VerticalBarSeries;
    // const firstSeries = [{x: 'A', y: 10}, {x: 'B', y: 5}, {x: 'C', y: 15}];
    // const secondSeriesBlueData = [{x: 'A', y: 12}, {x: 'B', y: 2}, {x: 'C', y: 11}];

    const bothAxisData = firstSeries.map((data, id) => {
        console.log('d.x')
        console.log(data.x)
        return ({
            x: data.x,
            y: Math.max(firstSeries[id].y, secondSeriesBlueData[id].y)
        })
    });

    return (
        <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
            <Grid item>
                <DiscreteColorLegend
                    items={[
                        {title: 'This Street', color: '#5C946E'},
                        {title: 'Similar Streets in ' + zipcode, color: '#33619e'}
                    ]}/>
                <XYPlot xType="ordinal" width={370} height={300} xDistance={0}>
                    <VerticalGridLines/>
                    <HorizontalGridLines/>
                    <XAxis height={500} tickLabelAngle={-20}/>
                    <YAxis/>
                    <BarSeries className="vertical-bar-series-example" data={firstSeries} color={'#5C946E'}/>
                    <BarSeries data={secondSeriesBlueData} color={'#33619e'}/>
                    <LabelSeries data={bothAxisData}/>
                </XYPlot>
            </Grid>
        </Grid>
    );
};

export default BarChart;
