import * as React from 'react';
import ReactMapGL, {Layer, Marker, Source} from 'react-map-gl';
import {useEffect, useState} from "react";
import Fade from "@material-ui/core/Fade";
import {useTheme} from "@material-ui/core/styles";

const token = 'pk.eyJ1IjoiYmxibGJsNDQiLCJhIjoiY2twcGY2OTN0MGZqazJ1cWpoM2h5eDl5NSJ9.5uYgki649OhuoI6Bw7R3ag'

export default function Map({allLocations, markerToDisplay, coords}) {
    // console.log("allLocations", allLocations)
    allLocations = []
    const [viewport, setViewport] = React.useState({
        longitude: coords.length > 0 ? parseFloat(coords[0][0]) : -74.0060,
        latitude: coords.length > 0 ? parseFloat(coords[0][1]) : 40.7128,
        zoom: coords.length > 0 ? 15 : 9
    });

    const [userLocation, setUserLocation] = useState({
        longitude: allLocations.length > 0 ? parseFloat(allLocations[0].long) : -74.0060,
        latitude: allLocations.length > 0 ? parseFloat(allLocations[0].lat) : 40.7128,
        // longitude: -74.0060,
        // latitude: 40.7128,
    })

    function metersPerPixel(latitude, zoomLevel) {
        var earthCircumference = 1.0000040075017;
        var latitudeRadians = latitude * (Math.PI / 180);
        return earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, zoomLevel + 8);
    };

    useEffect(() => {
        if (coords != null) {
            setViewport({
                longitude: parseFloat(coords[0][0]),
                latitude: parseFloat(coords[0][1]),
                zoom: viewport.zoom,
                transitionDuration: 2000,
                // transitionInterpolator: new FlyToInterpolator(),
            })
        }
    }, [coords])

    function locateUser() {
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position)
            setViewport({longitude: position.coords.longitude, latitude: position.coords.latitude, zoom: 20})
            setUserLocation({longitude: position.coords.longitude, latitude: position.coords.latitude})
        });
    }

    const dataOne = {
        type: "Feature",
        properties: {},
        geometry: {
            type: "LineString",
            coordinates: coords
        }
    };

    const theme = useTheme()
    const screenHeight = window.screen.height;
    const screenWidth = window.screen.width;
    const isMobile = screenWidth < 400
    return (
        <Fade in timeout={4000}>
            <div style={{top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000}}
            >
                <ReactMapGL mapboxApiAccessToken={token} {...viewport}
                            width="100%" height={isMobile ? screenHeight / 2 : screenHeight - 50}
                            onViewportChange={setViewport}
                            cooperativeGestures={true}
                            scrollEnabled={false} // <-- Disables one-finger panning
                            pitchEnabled={true} // <--- Enable two-finger zooming + panning
                            mapStyle={'mapbox://styles/blblbl44/ckppf81iz0v1917pr01v212no'}
                >
                    <Source id="polylineLayer" type="geojson" data={dataOne}>
                        <Layer
                            id="lineLayer"
                            type="line"
                            source="my-data"
                            layout={{
                                "line-join": "round",
                                "line-cap": "round"
                            }}
                            paint={{
                                "line-color": theme.palette.secondary.main,
                                "line-width": 5
                            }}
                        />
                    </Source>
                    {/*{allLocations.map(location => {*/}
                    {/*    return (*/}
                    {/*        <CustomMarker key={location.uid}*/}
                    {/*                      latitude={parseFloat(location.lat)}*/}
                    {/*                      longitude={parseFloat(location.long)} viewport={viewport}*/}
                    {/*                      name={location.name_only} setMarkerToDisplay={setMarkerToDisplay}*/}
                    {/*                      setViewport={setViewport}*/}
                    {/*                      icon={deduceIconType(location)}*/}
                    {/*                      locationObject={location}*/}
                    {/*        />)*/}
                    {/*})}*/}

                    {/*<CustomMarker*/}
                    {/*    // pointerEvents={'none'}*/}
                    {/*    // style={{pointerEvents: 'none'}}*/}
                    {/*    latitude={parseFloat(userLocation.latitude)}*/}
                    {/*    longitude={parseFloat(userLocation.longitude)}*/}
                    {/*    name={"You Are Here"}*/}
                    {/*    viewport={viewport}*/}
                    {/*    icon={<PulsingDot/>}*/}
                    {/*    // icon={<LocationOn/>}*/}
                    {/*    setMarkerToDisplay={setMarkerToDisplay}*/}
                    {/*    setViewport={setViewport}*/}
                    {/*    locationObject={null}*/}
                    {/*/>*/}

                </ReactMapGL>

                {/*<Button style={{*/}
                {/*    position: "fixed",*/}
                {/*    bottom: 30,*/}
                {/*    right: 20,*/}
                {/*    backgroundColor: '#268DA8',*/}
                {/*}}*/}
                {/*        variant={"contained"}*/}
                {/*        color="primary" aria-label="add"*/}
                {/*        onClick={() => locateUser()}>*/}
                {/*    Locate Me*/}
                {/*</Button>*/}
            </div>
        </Fade>
    );
}

function CustomMarker(props) {
    const [showPopup, togglePopup] = React.useState(false);

    const {longitude, latitude, viewport, name, setViewport, setMarkerToDisplay, icon, locationObject} = props;

    return (
        // <div style={{transition: "ease-in", zIndex: 1}}>
        <Marker latitude={latitude} longitude={longitude} offsetLeft={-20} offsetTop={-10}>
            <div
                onClick={() => {
                    togglePopup(!showPopup)
                    // setViewport({longitude: locationObject.long,
                    // latitude: locationObject.lat,
                    // zoom: viewport.zoom})
                    setMarkerToDisplay(locationObject)
                }}>{icon}</div>
        </Marker>
    );
}
