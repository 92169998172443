import React, {useEffect, useState} from 'react';
import {
    DiscreteColorLegend,
    HorizontalGridLines,
    LabelSeries, LineSeries, VerticalBarSeries,
    VerticalBarSeriesCanvas,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis
} from "react-vis";
import {Chart} from "react-google-charts";
import Grid from "@material-ui/core/Grid";

const LineChart = ({data, zipcode}) => {
    // const {useCanvas} = this.state;
    // const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS';
    const [firstSeries, setFirstSeries] = useState(null)
    const [secondSeriesBlueData, setSecondSeriesBlueData] = useState([{x: 0, y: 0}])


    useEffect(() => {
        function extractName(dbName, similarStreet) {
            let nameArray = dbName.toLowerCase().split('_')
            if (similarStreet) {
                return nameArray[3] + "-" + nameArray[4]
            } else {
                return nameArray[2] + "-" + nameArray[3]
            }
        }

        let firstSeriesLocal = [];
        let firstSeriesKeys = Object.keys(data.streetStats[0])
        let firstSeriesValues = Object.values(data.streetStats[0])
        let secondSeriesValues = Object.values(data.similarStreetStats[0])

        firstSeriesLocal.push(['hour', 'weekday', 'weekend'])
        for (let i = 0; i < firstSeriesKeys.length; i++) {
            if (firstSeriesKeys[i].includes("weekday")) {
                firstSeriesLocal.push(
                    [extractName(firstSeriesKeys[i], false),
                        parseFloat(firstSeriesValues[i]),
                        parseFloat(secondSeriesValues[i])])
            }
        }

        setFirstSeries(firstSeriesLocal)
    }, [data])

    const options = {
        title: "",
        curveType: "function",
        legend: {position: "top"},
        colors: ['#5C946E', '#33619e']
    };

    return (
        <Grid container alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
            <Grid item>
                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="100%"
                    data={firstSeries}
                    options={options}
                />
            </Grid>
        </Grid>
    );
};

export default LineChart;
